.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
}

.report-input {
    display: grid;
    grid-template-rows: max-content 300px 1fr;
}

.title {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    font-size: 16px;
}

.news-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}
img{
    max-width: 80vw;
}
.card-container {
    margin-top: 30px;
    max-height: 400px;
    display: grid;
    grid-template-rows: max-content 300px 10px;
}

@media screen and (max-width: 850px) {
    .feature-box-container {
        margin-top: 50px;
        height: 1200px;
        flex-direction: column;
    }

    .allience-container {
        flex-direction: column;
    }
}

@media (min-width: 1281px) {
    .allience-container {
        flex-direction: row;
    }

    .feature-box-container {
        flex-direction: row;
    }

    .title {
        margin-top: 20px;
        width: 100%;
        font-size: 24px;
    }

    .wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
        grid-gap: 20px;
    }

    .report-input {
        display: grid;
        grid-template-rows: max-content 300px 1fr;
    }

    .news-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(clamp(20rem, calc(20rem + 2vw), 22rem), 1fr));
    }

    .card-container {
        margin-top: 0px;
        max-height: 500px;
        display: grid;
        grid-template-rows: max-content clamp(20rem, calc(20rem + 2vw), 22rem) 1fr;
    }
}


*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    display: grid;
    place-items: center;
    height: 100vh;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin-block: 2rem;
    gap: 2rem;
}

.post-img {
    max-width: 100%;
    display: block;
    object-fit: cover;
}

.card {
    display: flex;
    flex-direction: column;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    background: white;
}

.card__body {
    min-height: 150px;
    height: min-content;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}


.tag {
    align-self: flex-start;
    padding: .25em .75em;
    border-radius: 1em;
    font-size: .75rem;
}

.tag+.tag {
    margin-left: .5em;
}

.tag-blue {
    background: #56CCF2;
    background: linear-gradient(to bottom, #2F80ED, #56CCF2);
    color: #fafafa;
}

.tag-brown {
    background: #D1913C;
    background: linear-gradient(to bottom, #FFD194, #D1913C);
    color: #fafafa;
}

.tag-red {
    background: #cb2d3e;
    background: linear-gradient(to bottom, #ef473a, #cb2d3e);
    color: #fafafa;
}

.card__body h4 {
    font-size: 1.5rem;
    text-transform: capitalize;
}

.card__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
}

.user {
    display: flex;
    gap: .5rem;
}

.user__image {
    border-radius: 50%;
}

.user__info>small {
    color: #666;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.column {
    flex-direction: column;
}

.feature-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    min-height: 550px;
}

.feature-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 360px;
    height: 360px;
    background: #fff;
    border-radius: 6px;
    padding: 32px 30px 32px;
    gap: 10px;
    box-shadow: 0 0 20px 0 rgb(62, 28, 131, 8%);
}