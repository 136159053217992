@import url("https://fonts.googleapis.com/css2?family=Roboto");
@import url("http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900");

html,
body,
html * {
  font-family: 'Roboto', sans-serif;
}

html {
  font-family: 'Roboto', sans-serif;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: black;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.mainDiv {
  background-color: #f8faf9;
  display: flex;
  flex-direction: column;
}

.cellPhoneMenu {
  width: 50px;
  display: none;
}

.cellphoneFullScreen {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(230, 236, 236);
  opacity: 50%;
  z-index: 100;
}

@media screen and (max-width: 750px) {
  .cellPhoneMenu {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    color: black;
    background-color: transparent;
  }

  .cellphoneFullScreen {
    position: fixed;
  }
}

.header {
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgb(62, 28, 131, 10%);
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  z-index: 1002;
}

@keyframes slidein {
  from {
    right: -200px;
  }

  to {
    right: 0;
  }
}

@media screen and (max-width: 720px) {
  .header {
    color: black;
    box-shadow: 0 0 20px 0 rgb(62, 28, 131, 10%);
    display: none;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    width: 200px;
    animation-duration: 0.5s;
  }

  .navBar {
    display: none;
  }

  .Visible {
    display: flex;
  }
}


.menu {
  font: bold 14px/1.4 "Open Sans", arial;
  font-size: 0.9em;
  height: 25px;
  line-height: 20px;
  text-align: center;
  color: black;
  text-decoration: none;
  position: relative;
  opacity: 0.8;
  margin: 30px;
}

.menu:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1.5px;
  right: 50%;
  position: absolute;
  background: black;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  opacity: 0.8;
}

.menu:hover:after {
  width: 120%;
  right: -10%;
}
.menuContainer{
  display: flex;
  align-items: center;
}

@media screen and (max-width: 750px) {

  .menuContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .menu {
    width: 150px;
    color: black;
    height: 30px;
    text-align: left;
  }

  .menu:after {
    background: white;
  }
}

.navBar {
  height: 60px;
  margin-right: 0px;
  background-color: transparent;
  border: red 3px solid;
}


.container {
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.titleContainer {
  font-size: 4rem;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 25%;
  padding: 5% 10% 5% 10%;
  background-color: rgb(255, 255, 255, 0.6);
  z-index: 5;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 720px) {
  .container {
    margin-top: 0;
  }

  .titleContainer {
    padding: 10% 12% 10% 12%;
    font-size: 9vw;
  }
}



@media screen and (max-width: 800px) {}

@media screen and (max-width: 800px) {
  .swiperContainer {
    width: 80%;
  }

  .testi {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    background-position: center;
  }

  .testiWords {
    font-size: 3.5vw;
    margin-left: 10%;
    margin-right: 10%;
    padding: 10%;
    padding-bottom: 15%;
  }
}

.wordCenter {
  text-align: center;
}

.bookButton {
  margin: auto;
  width: 200px;
  height: 60px;
  background: rgb(14, 107, 114);
  border: 3px solid white;
  color: white;
  font-size: 1.2rem;
  font-style: bold;
  letter-spacing: 1.5px;
  z-index: 3;
}

.bookButton:hover {
  color: rgb(2, 75, 75);
  background: rgb(223, 248, 245);
  border: 2px solid rgb(2, 75, 75);
}

.footer {
  background-color: #ffff;
  border-top: 1px solid #eef1f5;
  color: rgb(93, 94, 94);
  font-size: 1em;
  margin-top: auto;
  width: 100%;
  height: 250px;
  padding-top: 2%;
  padding-bottom: 10%;
  padding-left: 12%;
  padding-right: 12%;
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.footInfoContainer {
  width: 60%;
  margin-right: 8%;
  display: flex;
  justify-content: space-between;
}

.footMenu {
  padding: 10px;
}

.footInfo {
  line-height: 22px;
  font-size: 0.9rem;
  padding-top: 15px;
}

.footIcon {
  width: 35px;
  height: 35px;
  margin: 5px;
  border-radius: 50%;
  border: none;
  background-color: white;
  color: rgb(71, 85, 80);
}

.footIcon:hover {
  color: white;
  background-color: rgb(139, 138, 138);
}

.footIconInHead {
  margin-top: 12px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.svgIcon {
  width: 20px;
  height: 20px;
}

.link-in-button {
  text-decoration: none;
  color: inherit;
}

.link-in-button:visited {
  color: inherit;
}

@media screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
    height: 330px;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footInfoContainer {
    width: 100%;
    margin: auto;
    justify-content: space-around;
  }

  .footIconContainer {
    margin: auto;
  }
}

.srvPage {
  color: grey;
}

.post-container {
  padding: 70px 160px 70px 160px;
}

.wording {
  padding-top: 2%;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.srvPageTitle {
  color: rgb(90, 90, 90);
}

.infoDiv {
  width: 100%;
  background-color: rgb(228, 234, 238);
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 100px;
  padding-bottom: 50px;
  margin: auto;
  margin-bottom: 60px;
  display: flex;
}

@media screen and (max-width: 800px) {
  .post-container {
    padding: 70px 40px 70px 40px;
  }

  .infoDiv {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 100px;
    padding-bottom: 50px;
    flex-direction: column;
    padding-top: 20px;
    margin-top: 0;
  }
}

.infoDiv2 {
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 70px;
}

.infoDiv3 {
  margin-bottom: 100px;
}

.defaultBackground {
  background-color: #f8faf9;
}

.whiteBackground {
  background-color: white;
}

.srvPagePic2 {
  width: 270px;
  height: 270px;
  margin-top: 5%;
  margin-left: 30px;
  margin-right: 30px;
}

@media screen and (max-width: 800px) {
  .srvPagePic2 {
    display: none;
  }
}

.smallButton {
  width: 150px;
  height: 40px;
  margin-right: 15%;
  background: rgb(146, 173, 175);
  border: 2px solid white;
  color: white;
  font-size: 1rem;
  font-style: bold;
  letter-spacing: 1.5px;
}

/****************** ----------about page----------- ***************/
.fontColor {
  font-family: 'Roboto', sans-serif;
  color: rgb(158, 120, 120);
}

.fontColor2 {
  font-family: 'Roboto', sans-serif;
  color: rgb(59, 96, 102);
}

.fontColor3 {
  font-family: 'Roboto', sans-serif;
  color: rgb(41, 60, 65);
}

.pholosophy {
  color: rgb(54, 44, 36);
  padding: 20%;
  padding-top: min(20%, 180px);
  font-size: 1.8vw;
  font-size: 24px;
}

.infoDivAboutPage {
  background-color: rgb(228, 234, 238);
  display: flex;
}

.aboutWording {

  text-align: right;
  white-space: pre-wrap;
}

@media screen and (max-width: 900px) {
  .infoDivAboutPage {
    flex-direction: column;
  }

  .aboutWording {

    text-align: left;
  }

  .marginB {
    margin-bottom: 15%;
  }
}

/****************** ----------contact page ----------- ***************/
.text-text {
  padding-top: 30px;
  display: flex;
  flex: 35% 35%;
  gap: 25%;
  width: 70%;
  margin-left: 17%;
  margin-bottom: 100px;
  font-size: max(1.2vw, 1rem);
  color: rgb(88, 88, 88);
}

.mapDiv {
  width: 70%;
  max-height: 500px;
  margin: auto;
  margin-bottom: 250px;
}

.allience-container {
  max-height: 500px;
  gap: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .text-text {
    flex-direction: column;
    margin-top: 60px;
    gap: 25px;
  }

  .mapDiv {
    width: 95%;
  }
}


@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  display: grid;
  place-items: center;
  height: 100vh;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin-block: 2rem;
  gap: 2rem;
}

.card {
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(20rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: white;

}

.card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}


.tag {
  align-self: flex-start;
  padding: .25em .75em;
  border-radius: 1em;
  font-size: .75rem;
}

.tag+.tag {
  margin-left: .5em;
}

.tag-blue {
  background: #56CCF2;
  background: linear-gradient(to bottom, #2F80ED, #56CCF2);
  color: #fafafa;
}

.tag-brown {
  background: #D1913C;
  background: linear-gradient(to bottom, #FFD194, #D1913C);
  color: #fafafa;
}

.tag-red {
  background: #cb2d3e;
  background: linear-gradient(to bottom, #ef473a, #cb2d3e);
  color: #fafafa;
}

.card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.card__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  gap: .5rem;
}

.user__image {
  border-radius: 50%;
}

.user__info>small {
  color: #666;
}